import React from 'react';

import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';

//components

import ImageBackground from '../../components/imagebackground';
import SchoolCard from '../../components/motorrad/schoolcard';
import settings from '../../../settings';

//images
import onroadmotorcycle from '../../images/motorrad/gstrophy/us-qualifier.jpg';
import msfriding from '../../images/motorrad/gstrophy/tropphy-prep-course.jpg';
import topbannerDesktop from '../../images/motorrad/gstrophy/gs-trophy-banner.jpg';
import topbannerMobile from '../../images/motorrad/gstrophy/gs-trophy-banner.jpg';
const internationalGsTrophy = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/gstrophy/gs-trophy-location.jpg`;
const redemptionSundayBanner = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/gstrophy/redemption-sunday-banner.jpg`;

const GsTrophyPage = () => (
  <Layout>
    <SEO title="The GS Trophy | Motorrad"  description=""/>
    <div className="motorrad-schools">
      <ImageBackground
        imageDesktop={topbannerDesktop}
        imageMobile={topbannerMobile}
        imageAlt="BMW Classes Dirty Tire Top Banner"
      />
      <section className="school gstrophy">
        <div className="school__header">
          <h1>GS Trophy</h1>
          <p>
            The destination of the International GS Trophy 2024 has been decided: Namibia. 
            Now that the GS Trophy has travelled throughout the globe, it returns to where 
            it all began - Africa. Namibia impresses with beautiful landscapes, the 
            “Big Five” and many other animal species. The National Qualifiers are happening 
            right here at the BMW Performance Center, and will decide who gets a chance to 
            tackle this special stage.
          </p>
        </div>
        <div className="onroad">
          <SchoolCard
            rowType="row"
            image={msfriding}
            imageAlt="TWO-DAY GS TROPHY PREP COURSE"
            header="TWO-DAY GS TROPHY PREP COURSE"
            priceOne="$1,800 - Using our BMW bikes"
          >
            <p>
              This class is geared toward the hopeful GS Trophy competitor. In this class, 
              we’ll work on the basic adventure rider skills needed and challenge those 
              skills in a competitive way. Drills include both individual and team GS 
              Trophy-style challenges. This class is not intended to teach adventure riding. 
              You must have off-road riding experience and a solid base set of riding skills.
            </p>
            <p>
              Class dates: May 24-25, July 5-6 and September 20-21, 2023
            </p>
            <a href="tel:1-888-345-4269"  rel="noopener noreferrer"><button className={`btn btn-bordered`}>CALL 888-345-4269 TO RESERVE YOUR SEAT NOW</button></a>
          </SchoolCard>
          <SchoolCard
            rowType="row"
            image={onroadmotorcycle}
            imageAlt="2024 GS TROPHY QUALIFIER PARTICIPANT"
            header="2024 GS TROPHY QUALIFIER PARTICIPANT"
            priceOne="$449">
            <p>Includes Qualifier entry fee, competition jersey, personalized parking stall, and all meals, snacks and water beginning with dinner Thursday evening through dinner on Saturday evening. 
              <br /> <br />The only location for the national qualifier will be here at the BMW Performance Center.<br /><br />
              <div className='dates'>Dates:</div>
              Thursday, October 12 2023 - Check-in and Welcome Competition Friday, October 13 and Saturday, October 14 - Competition
              <br /><br />
              On Saturday evening, October 14th, the three male finalists that will represent the U.S. at the final event in Namibia in 2024 will be announced.
              <br /><br />
              Two female finalists will also be selected to advance to the female International Qualifier Event. More details about the Women’s International Qualifier date and location will be announced soon.
              <br /><br />
              Please note that we do not rent bikes for the competition. You must bring your own.
              <br /><br />
              Camping is available at surrounding campgrounds or at the MOA Headquarters, located just three miles from the Performance Center.
            </p>
      
            <div>
              <a href="https://www.bmwmotorcycles.com/en/experience/events/gs-trophy-2024.html#/section-u-s-a-national-qualifiers"  rel="noopener noreferrer"><button className={`btn btn-bordered`}>VISIT MOTORRAD USA FOR MORE INFORMATION</button></a>
            </div>
            <a href="https://pds.eventsbmw.com/?brand=Motorrad&date=2023-10-01&class=GS_Trophy_Qualifier_Participant"  rel="noopener noreferrer"><button className={`btn btn-bordered`}>BOOK NOW</button></a>
            
            <br />
            <br />
            <h3> 2024 GS TROPHY QUALIFIER VOLUNTEER</h3>
            <p>
              Volunteers play a vital role in the Qualifier, and will get to see the best 
              adventure riders up close as they give it their all for a coveted seat on 
              the U.S. Team. For more information on the important functions you’ll provide, 
              please call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a>.
            <br />
            <br />
            Includes volunteer jersey and all meals, snacks and water beginning with dinner Thursday evening through dinner on Saturday evening.
            </p>
            <a href="https://pds.eventsbmw.com/?brand=Motorrad&date=2023-10-01&class=GS_Trophy_Qualifier_Volunteer"  rel="noopener noreferrer"><button className={`btn btn-bordered`}>BOOK NOW</button></a>
          </SchoolCard>
          <SchoolCard
            rowType="row"
            image={redemptionSundayBanner}
            imageAlt="REDEMPTION SUNDAY"
            header="REDEMPTION SUNDAY"
          >
            <p>
              Even if you’re eliminated from the main event, the fun doesn’t have to end when you register for the all-new Redemption Sunday.
            <br/><br/>
              Open to 2023 GS Trophy Qualifier Participants and Volunteers. Use of our bikes is included in the cost, and no discounts will be offered to ride your own
            <br/><br/>
            <div className='dates'>Half-Day Morning Session</div>
            Price: $399 <br/>
            There will be three instructors available to assist you as you practice on the GS Trophy course during this open riding session.<br/>
            Limited to the first 12 registrants.*
            <br/><br/>
            <div className='dates'>Full-Day Session</div>
            Price: $720 <br/>
            In the morning, four instructors will be available to help hone your riding skills though various obstacles and exercises
            In the afternoon, we’ll head over to the GS Trophy course and attempt to improve your overall performance.<br/>
            Limited to the first 12 registrants.*
            <br/><br/>
            *Upon registration for the GS Trophy Qualifier Participant or Volunteer, you’ll receive a registration confirmation email with a special code allowing you to participate in either of the Redemption Sunday sessions. Booking is first come, first serve. Please call in advance to book your session.
            </p>
          </SchoolCard>
          <SchoolCard
            rowType="row"
            image={internationalGsTrophy}
            imageAlt="INTERNATIONAL GS TROPHY SUMMER 2024"
            header="INTERNATIONAL GS TROPHY SUMMER 2024"
          >
            <p>
              Should you advance from National Qualifiers, you’ll be headed to Namibia in 
              late summer 2024. It’s an Enduro ride like none other, and it could be yours.
            </p>
            <div className="button__links">
              <a href="https://www.bmwmotorcycles.com/en/experience/gs-trophy-2024.html" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>LEARN MORE ABOUT THE INTERNATIONAL GS TROPHY</button></a>
            </div>
          </SchoolCard>
        </div>
      </section>
    </div>
  </Layout>
);

export default GsTrophyPage;